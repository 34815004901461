<template>
  <el-dialog
    title="申请名单"
    :visible.sync="visible"
    class="mylive-apply-dialog"
    :append-to-body="true"
    width="468px"
    @close="$emit('close')"
  >
    <div>
      <div class="title-header">
        直播名称
      </div>
      <div class="title-theme">
        {{ theme }}
      </div>
      <div class="apply-wrapper">
        <div class="apply-header">
          申请人
        </div>
        <el-button
          size="mini"
          :disabled="noUnAgree"
          plain
          @click="handleAllAgree"
        >
          全部同意
        </el-button>
      </div>
      <div class="apply-list">
        <template v-for="(item, index) in applyList">
          <div
            :key="item.id"
            class="apply"
          >
            <logo-panel
              :info="item"
              class="avatar"
            />
            <div class="intro">
              <div class="apply-name">
                {{ item.name }}
              </div>
              <div class="apply-identity">
                <span v-if="item.entityName && item.position">
                  {{ item.entityName }} | {{ item.position }}
                </span>
                <span v-else-if="item.entityName && !item.position">
                  {{ item.entityName }}
                </span>
                <span v-else-if="!item.entityName && item.position">
                  {{ item.position }}
                </span>
              </div>
            </div>
            <el-button
              type="primary"
              :disabled="item.examineStatus !== 1"
              size="mini"
              @click="handleAgree(item, index)"
            >
              {{ item.examineStatus===1 ? '同意' :'已同意' }}
            </el-button>
          </div>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import LogoPanel from '@/views/components/LogoPanel';
import { getApplicationList, setAgreeApply, setAgreeApplyAll } from '@/services/room';

export default {
  name: 'ApplyDialog',
  components: {
    LogoPanel,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: [String, Number],
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noUnAgree: false,
      applyList: [],
    };
  },
  mounted() {
    this.getApplicationList();
  },
  methods: {
    async getApplicationList() {
      try {
        const data = await getApplicationList(this.roomId);
        if (data?.data) {
          this.applyList = Object.freeze(data.data);
          const unApply = this.applyList.filter((item) => {
            return item.examineStatus === 1;
          });
          this.noUnAgree = !unApply.length;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // 同意申请
    async handleAgree(item, index) {
      if (item.examineStatus === 2) return;
      try {
        await setAgreeApply({
          liveRoomId: this.roomId,
          uid: item.uid,
          examineStatus: 2,
        });
        this.applyList[index].examineStatus = 2;
        this.$forceUpdate();
      } catch (error) {
        // console.log(error);
      }
    },
    // 全部同意申请
    async handleAllAgree() {
      if (this.noUnAgree) return;
      try {
        await setAgreeApplyAll(this.roomId);
        const formatData = Object.freeze(this.applyList);
        this.applyList = formatData.map((i) => {
          i.examineStatus = i.examineStatus === 2;
          return i;
        });
        this.$forceUpdate();
        this.noUnAgree = true;
      } catch (error) {
        // console.log(error)
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mylive-apply-dialog {
  .title-header {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333B44;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 500;
  }
  .title-theme {
    margin-top: 8px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333B44;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
  }
  .apply-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
  }
  .apply-header {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333B44;
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 500;
  }
  .apply {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &:first-child {
      margin-top: 20px;
    }
    .el-button {
      margin-top: 6px;
    }
  }
  .avatar {
    .user-logo-item {
      cursor: default;
    }
  }
  .apply-name {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333B44;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
  }
  .apply-identity {
    margin-top: 2px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666B71;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
  }
  .intro {
    flex-grow: 1;
  }
  .el-button {
    width: 80px;
    height: 28px;
  }
  .el-button.is-disabled{
    background: #F2F4F6;
    border: 0;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #959AA2;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
.mylive-apply-dialog {
  .el-dialog__body {
    padding: 30px;
  }
}
</style>
